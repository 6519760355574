import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';
import PageWrapper from '../components/common/PageWrapper';
import { useCustomers } from '../context/CustomerContext';
import { formatDateTime } from '../utils/dateFormatter';
import { formatMoney } from '../utils/formatMoney';
import { useTheme } from '@mui/material/styles';
import CustomerActionButtons from '../components/customer/CustomerActionButtons';
import StatusChip from '../components/customer/StatusChip';
import StyledPaper from '../components/common/StyledPaper';

const CustomerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { currentCustomer, loading, error, setCustomerId } = useCustomers();
  const theme = useTheme();

  useEffect(() => {
    if (id) {
      setCustomerId(id);
    }
    return () => {
      setCustomerId(undefined);
    };
  }, [id]);

  const memoizedTitle = useMemo(() => {
    return currentCustomer ? (
      <Grid container spacing={2} alignItems="left">
        <Grid item>
          <Typography variant="h4">{currentCustomer.name}</Typography>
        </Grid>
        <Grid item>
          <StatusChip status={currentCustomer.status} />
        </Grid>
      </Grid>
    ) : (
      'Customer details'
    );
  }, [currentCustomer]);

  return (
    <PageWrapper error={error} loading={loading} title={memoizedTitle}>
      {currentCustomer && (
        <StyledPaper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Balance:
              </Typography>
              <Typography variant="body1">
                {formatMoney(
                  currentCustomer.balance.amount,
                  currentCustomer.balance.currency
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Email:
              </Typography>
              <Typography variant="body1">
                <a href={`mailto:${currentCustomer.email}`} type="email">
                  {currentCustomer.email}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Phone:
              </Typography>
              <Typography variant="body1">{currentCustomer.phone}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color={theme.palette.grey[500]}>
                Onboarding Date:
              </Typography>
              <Typography variant="body1">
                {formatDateTime(currentCustomer.onboardedAt)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomerActionButtons
                id={currentCustomer.uuid}
                actions={currentCustomer.actions}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      )}
    </PageWrapper>
  );
};

export default CustomerDetails;
