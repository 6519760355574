import React from 'react';
import { useConfig } from '../../context/ConfigProvider';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ConfigInfo: React.FC = () => {
  const config = useConfig();
  const theme = useTheme();

  return (
    <Typography variant="body1" color={theme.palette.text.secondary}>
      mode: {config.mode} | instance: {config.instance}
      {/* | Keycloak URL:{' '} {config.keycloakBaseUrl}*/}
    </Typography>
  );
};

export default ConfigInfo;
