import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import Index from './components/layout';
import NotFound from './pages/NotFound';
import { NotificationsProvider } from './context/NotificationsContext';
import CustomerRoutes from './routes/CustomerRoutes';

const App: React.FC = () => (
  <Router>
    <NotificationsProvider>
      <Index>
        <Routes>
          <Route path="/" element={<Navigate to="/customers" />} />
          <Route path="/customers/*" element={<CustomerRoutes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Index>
    </NotificationsProvider>
  </Router>
);

export default App;
