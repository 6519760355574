import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import menuItems from '../../data/menuItems';
import { Typography } from '@mui/material';

interface PageTitleProps {
  title?: React.ReactNode | string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  const location = useLocation();

  const currentPage = useMemo(() => {
    if (title) return { text: title };
    return menuItems.find(item => item.path === location.pathname);
  }, [location.pathname, title]);

  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {currentPage?.text || '404 - Page not found'}
    </Typography>
  );
};

export default PageTitle;
