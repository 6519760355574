import React, { useEffect, useState, useCallback } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Customer, CustomerFormData, Status } from '../../types/customerTypes';
import { Currency } from '../../types/common';
import StyledPaper from '../common/StyledPaper';

interface CustomerFormProps {
  onSubmit: (data: CustomerFormData) => void;
  customer?: Customer;
}

const initialForm: CustomerFormData = {
  name: '',
  email: '',
  phone: '',
  onboardedAt: new Date().toISOString().slice(0, 16),
  status: Status.IN_REVIEW,
  balance: {
    amount: 0,
    currency: Currency.USD,
  },
};

const CustomerForm: React.FC<CustomerFormProps> = ({ onSubmit, customer }) => {
  const [formData, setFormData] = useState<CustomerFormData>(initialForm);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (customer) {
      const { onboardedAt, ...rest } = customer;
      setFormData({
        ...rest,
        onboardedAt: new Date(onboardedAt).toISOString().slice(0, 16),
      });
    }
  }, [customer]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const [field, subfield] = name.split('.');

      setFormData(prevState => ({
        ...prevState,
        [field as keyof CustomerFormData]: subfield
          ? {
              ...(prevState[field as keyof CustomerFormData] as Record<
                string,
                unknown
              >),
              [subfield]: value,
            }
          : value,
      }));
    },
    []
  );

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.onboardedAt)
      newErrors.onboardedAt = 'Onboarding Date is required';
    if (!formData.status) newErrors.status = 'Status is required';
    if (formData.balance.amount == null)
      newErrors['balance.amount'] = 'Account Balance is required';
    if (!formData.balance.currency)
      newErrors['balance.currency'] = 'Currency is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) onSubmit(formData);
  };

  return (
    <StyledPaper>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Onboarding Date"
              name="onboardedAt"
              type="datetime-local"
              value={formData.onboardedAt}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.onboardedAt}
              helperText={errors.onboardedAt}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Account Status"
              name="status"
              select
              value={formData.status}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.status}
              helperText={errors.status}
            >
              <MenuItem value={Status.ACTIVE}>ACTIVE</MenuItem>
              <MenuItem value={Status.IN_REVIEW}>IN REVIEW</MenuItem>
              <MenuItem value={Status.CLOSED}>CLOSED</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Account Balance"
              name="balance.amount"
              type="number"
              value={formData.balance.amount}
              onChange={handleInputChange}
              fullWidth
              error={!!errors['balance.amount']}
              helperText={errors['balance.amount']}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Currency"
              name="balance.currency"
              select
              value={formData.balance.currency}
              onChange={handleInputChange}
              fullWidth
              error={!!errors['balance.currency']}
              helperText={errors['balance.currency']}
            >
              {Object.values(Currency).map(currency => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </StyledPaper>
  );
};

export default CustomerForm;
