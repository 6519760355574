import React from 'react';
import CustomerForm from '../components/customer/CustomerForm';
import PageTitle from '../components/common/PageTitle';
import { createCustomer } from '../services/customerService';
import { CustomerFormData } from '../types/customerTypes';
import { useNotifications } from '../context/NotificationsContext';
import { useNavigate } from 'react-router-dom';

const CreateCustomer: React.FC = () => {
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const handleSubmit = async (data: CustomerFormData) => {
    try {
      const { uuid } = await createCustomer(data);
      addNotification('Customer created successfully', 'success');
      navigate(`/customers/${uuid}`);
    } catch (error) {
      addNotification('Failed to create customer', 'error');
    }
  };
  return (
    <>
      <PageTitle />
      <CustomerForm onSubmit={handleSubmit} />
    </>
  );
};

export default CreateCustomer;
