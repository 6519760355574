import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ErrorMessageProps {
  message: string;
}
const StyledTypography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return <StyledTypography variant="body1">{message}</StyledTypography>;
};

export default ErrorMessage;
