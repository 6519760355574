import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import menuItems from '../../data/menuItems';
import { styled } from '@mui/material/styles';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: 'none',
  padding: 0,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  '& .MuiListItemIcon-root': {
    color: 'inherit',
  },
  '&:hover': {
    background: 'none',
    color: theme.palette.primary.light,
  },
  '& .MuiTouchRipple-root': {
    display: 'none', // Disable ripple effect
  },
}));

const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <List>
      {menuItems.map((item, index) => (
        <ListItem component={Link} to={item.path} key={index}>
          <StyledListItemButton selected={location.pathname === item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </StyledListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default Sidebar;
