import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PageTitle from '../components/common/PageTitle';

const CenteredBox = styled(Box)({
  textAlign: 'center',
  marginTop: 5,
});

const NotFound: React.FC = () => {
  return (
    <CenteredBox>
      <PageTitle />
      <Typography variant="body1">
        The page you are looking for does not exist.
      </Typography>
    </CenteredBox>
  );
};

export default NotFound;
