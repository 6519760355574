import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Customers from '../pages/Customers';
import CreateCustomer from '../pages/CreateCustomer';
import CustomerDetails from '../pages/CustomerDetails';
import UpdateCustomer from '../pages/UpdateCustomer';
import NotFound from '../pages/NotFound';
import { CustomerProvider } from '../context/CustomerContext';

const CustomerRoutes: React.FC = () => (
  <CustomerProvider>
    <Routes>
      <Route path="/:id" element={<CustomerDetails />} />
      <Route path="/:id/edit" element={<UpdateCustomer />} />
      <Route path="/" element={<Customers />} />
      <Route path="/create" element={<CreateCustomer />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </CustomerProvider>
);

export default CustomerRoutes;
