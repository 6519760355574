import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { toolbarHeight } from '../../theme';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: theme.spacing(3),
  height: `calc(100vh - ${toolbarHeight}px)`,
  overflow: 'auto',
  backgroundColor: theme.palette.grey[100],
}));

const Main: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StyledBox>{children}</StyledBox>
);

export default Main;
