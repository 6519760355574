import React from 'react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import slugToWords from '../../utils/slugToWords';
import { Status } from '../../types/customerTypes';

interface StatusChipProps {
  status: Status;
}

const getStatusChipColor = (status: Status) => {
  const theme = useTheme();

  switch (status) {
    case 'ACTIVE':
      return theme.palette.success.light;
    case 'CLOSED':
      return theme.palette.grey[300];
    case 'IN_REVIEW':
      return theme.palette.warning.light;
    default:
      return theme.palette.grey[500];
  }
};

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const theme = useTheme();
  const backgroundColor = getStatusChipColor(status);
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Chip
      size="small"
      label={slugToWords(status)}
      sx={{
        backgroundColor,
        color: textColor,
      }}
    />
  );
};

export default StatusChip;
