import { createTheme } from '@mui/material/styles';

export const drawerWidth = 240;
export const toolbarHeight = 64;

const theme = createTheme({
  palette: {
    primary: {
      main: '#4B2899',
    },
    secondary: {
      main: '#77DAC9',
    },
    error: {
      main: '#D92B3C',
    },
    warning: {
      main: '#FFA900',
      light: '#FAF5CD',
    },
    info: {
      main: '#00B0FF',
    },
    success: {
      main: '#00A300',
      light: '#0F8579',
    },
    background: {
      default: '#E6E3ED',
    },
  },
});

export default theme;
