/**
 * Converts a slug string to a human-readable string with capitalized words.
 *
 * @param {string} slug - The slug string to convert.
 * @returns {string} - The converted string with words capitalized.
 *
 * @example
 * // returns 'Hello World'
 * slugToWords('hello_world');
 */
const slugToWords = (slug: string): string => {
  return slug
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default slugToWords;
