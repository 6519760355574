import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 50, width: 100, mr: 2 }}>
      <svg
        width="96"
        height="77"
        viewBox="0 0 96 77"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M49.9968 49.1519V27.8143H45.8752V49.1519H49.9968ZM65.2672 36.8255L74.5472 49.1647H69.312L62.336 39.7439L59.3664 42.7135V49.1519H55.2448V27.8143H59.3664V37.2607L68.5952 27.8143H74.368L65.2672 36.8255Z"
          fill="white"
        />
        <path
          d="M45.4272 21.568C45.4272 22.9376 46.5408 24.0512 47.9104 24.0512C49.3184 24.0512 50.432 22.9376 50.432 21.568C50.432 20.16 49.3184 19.0464 47.9104 19.0464C46.5408 19.0336 45.4272 20.1472 45.4272 21.568Z"
          fill="white"
        />
        <path
          d="M59.5585 19.2896H55.0657V23.7824H59.5585V19.2896Z"
          fill="white"
        />
        <path
          d="M0.0256348 42.1888H3.87843C4.13443 44.6464 5.91363 46.0416 8.65283 46.0416C11.1104 46.0416 12.672 44.736 12.672 43.1616C12.672 37.4912 0.576035 42.7007 0.576035 33.5999C0.576035 29.9135 3.71203 27.4688 8.15363 27.4688C12.9792 27.4688 16.2816 30.1311 16.4864 34.1503H12.6336C12.3392 31.6927 9.97123 30.7199 8.15363 30.7199C5.95203 30.7199 4.46723 31.8207 4.46723 33.4719C4.46723 38.7583 16.6528 33.3439 16.6528 43.0719C16.6528 46.9632 13.4784 49.4592 8.65283 49.4592C3.66084 49.4719 0.320035 46.6816 0.0256348 42.1888ZM21.056 27.8143H25.1648V30.9888C26.432 28.8768 29.0176 27.4815 31.9744 27.4815C38.4 27.4815 42.0864 32.1408 42.0864 38.4768C42.0864 44.8128 38.2336 49.472 31.7696 49.472C28.9408 49.472 26.3936 48.1663 25.1648 46.1311V57.7663H21.056V27.8143ZM37.9392 38.4768C37.9392 34.0352 35.3152 31.0783 31.3856 31.0783C27.4048 31.0783 24.832 34.0352 24.832 38.4768C24.832 42.9184 27.4176 45.8751 31.3856 45.8751C35.3152 45.8751 37.9392 42.9184 37.9392 38.4768ZM95.7184 42.1888C94.9632 46.592 91.1104 49.472 85.8624 49.472C79.2576 49.472 75.1616 44.2624 75.1616 38.3488C75.1616 32.384 78.8864 27.4815 85.44 27.4815C92.0448 27.4815 95.552 32.1792 95.552 37.5936C95.552 38.144 95.5136 38.8223 95.4624 39.2063H79.1808C79.5136 43.2255 82.1376 45.9776 85.8624 45.9776C88.9984 45.9776 91.0208 44.6207 91.6224 42.2143H95.7184V42.1888ZM79.3088 36.1856H91.4048C91.1488 32.9216 88.9472 30.7711 85.44 30.7711C82.0992 30.7711 79.9488 32.6272 79.3088 36.1856Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
