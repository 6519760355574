import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { loadConfig } from '../services/configLoader';

interface Config {
  instance: string;
  mode: string;
  keycloakBaseUrl: string;
}

const ConfigContext = createContext<Config | undefined>(undefined);

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<Config | null>(null);

  useEffect(() => {
    loadConfig()
      .then(setConfig)
      .catch(error => {
        console.error('Failed to load configuration:', error);
      });
  }, []);

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
