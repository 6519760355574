import { PersonAdd, People, SvgIconComponent } from '@mui/icons-material';

type MenuItem = {
  text: string;
  icon: React.ReactElement<SvgIconComponent>;
  path: string;
};

const menuItems: MenuItem[] = [
  {
    text: 'Customers',
    icon: <People />,
    path: '/customers',
  },
  {
    text: 'Create customer',
    icon: <PersonAdd />,
    path: '/customers/create',
  },
];

export default menuItems;
