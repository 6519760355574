import React from 'react';
import { useNotifications } from '../../context/NotificationsContext';
import { Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)({
  width: '100%',
});

const NotificationsList: React.FC = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <>
      {notifications.map(notification => (
        <Snackbar
          key={notification.id}
          open={true}
          autoHideDuration={6000}
          onClose={() => removeNotification(notification.id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <StyledAlert
            onClose={() => removeNotification(notification.id)}
            severity={notification.type}
          >
            {notification.message}
          </StyledAlert>
        </Snackbar>
      ))}
    </>
  );
};

export default NotificationsList;
