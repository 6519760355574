import React, { useEffect } from 'react';
import CustomerForm from '../components/customer/CustomerForm';
import { updateCustomer } from '../services/customerService';
import { CustomerFormData } from '../types/customerTypes';
import { useNotifications } from '../context/NotificationsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomers } from '../context/CustomerContext';
import PageWrapper from '../components/common/PageWrapper';

const UpdateCustomer: React.FC = () => {
  const { addNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const { currentCustomer, loading, error, setCustomerId, update } =
    useCustomers();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) setCustomerId(id);
    return () => setCustomerId(undefined);
  }, [id, setCustomerId]);

  const handleSubmit = async (data: CustomerFormData) => {
    try {
      if (!id) {
        throw new Error();
      }
      const { uuid } = await updateCustomer(id, data);
      addNotification('Customer updated successfully', 'success');
      update();
      navigate(`/customers/${uuid}`);
    } catch {
      addNotification('Failed to update customer', 'error');
    }
  };

  return (
    <PageWrapper error={error} loading={loading} title="Update customer">
      {currentCustomer && (
        <CustomerForm onSubmit={handleSubmit} customer={currentCustomer} />
      )}
    </PageWrapper>
  );
};

export default UpdateCustomer;
