import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { ConfigProvider } from './context/ConfigProvider';
//ToDo: enable next 2 lines after Keycloak set up
// import keycloak from './keycloak';
// import { ReactKeycloakProvider } from '@react-keycloak/web';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <ThemeProvider theme={theme}>
        {/*ToDo: enable after Keycloak set up*/}
        {/*<ReactKeycloakProvider authClient={keycloak}>*/}
        <App />
        {/*</ReactKeycloakProvider>*/}
      </ThemeProvider>
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();
