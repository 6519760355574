import React from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { Action, Customer, Status } from '../../types/customerTypes';
import { PaginatedResponse } from '../../types/common';
import { formatDateTime } from '../../utils/dateFormatter';
import Table from '../common/Table';
import { formatMoney } from '../../utils/formatMoney';
import ActionButtons from './CustomerActionButtons';
import { useTheme } from '@mui/material/styles';
import StatusChip from './StatusChip';

interface CustomerTableProps {
  rows: PaginatedResponse<Customer>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
  loading: boolean;
}

export const getStatusChipColor = (status: Status) => {
  const theme = useTheme();

  switch (status) {
    case 'ACTIVE':
      return theme.palette.success.light;
    case 'CLOSED':
      return 'default';
    case 'IN_REVIEW':
      return theme.palette.warning.light;
    default:
      return 'default';
  }
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1, sortable: true },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => (
      <a href={`mailto:${params.value}`} type="email">
        {params.value}
      </a>
    ),
  },
  { field: 'phone', headerName: 'Phone', flex: 1, sortable: true },
  {
    field: 'onboardedAt',
    headerName: 'Onboarding Date',
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      formatDateTime(params.row.onboardedAt),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => (
      <StatusChip status={params.value as Status} />
    ),
  },
  {
    field: 'balance',
    headerName: 'Balance',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      formatMoney(params.row.balance.amount, params.row.balance.currency),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => (
      <ActionButtons
        actions={[...params.row.actions, Action.VIEW]}
        id={params.row.uuid}
      />
    ),
  },
];

const CustomerTable: React.FC<CustomerTableProps> = ({
  loading,
  paginationModel,
  rows,
  setPaginationModel,
  setSortModel,
  sortModel,
}) => (
  <Table
    rows={rows.items}
    columns={columns}
    paginationModel={paginationModel}
    setPaginationModel={setPaginationModel}
    loading={loading}
    rowCount={rows?.pagination?.total || 0}
    getRowId={item => item.uuid}
    sortModel={sortModel}
    setSortModel={setSortModel}
  />
);

export default CustomerTable;
