export interface PaginatedResponse<T> {
  items: T[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}
export interface Filter {
  field: string; // Fields that can be filtered
  operator: 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'gte' | 'lte'; // Example operators
  value: string | number | Date; // Value for filtering
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  // Add more currencies as needed
}
