import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowIdGetter,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { styled, Paper } from '@mui/material';

interface TableProps<T extends GridValidRowModel> {
  rows: T[];
  columns: GridColDef[];
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  loading: boolean;
  rowCount: number;
  getRowId: (row: T) => string;
  sortModel: GridSortModel;
  setSortModel: (model: GridSortModel) => void;
}

const StyledPaper = styled(Paper)({
  width: '100%',
  height: '100%',
  overflowX: 'auto',
});

const StyledDataGrid = styled(DataGrid)({
  width: '100%',
  height: '100%',
  overflowX: 'auto',
});

const Table = <T extends GridValidRowModel>({
  rows,
  columns,
  paginationModel,
  setPaginationModel,
  loading,
  rowCount,
  getRowId,
  sortModel,
  setSortModel,
}: TableProps<T>) => {
  return (
    <StyledPaper>
      <StyledDataGrid
        columns={columns}
        disableRowSelectionOnClick
        getRowId={getRowId as GridRowIdGetter<GridValidRowModel>}
        loading={loading}
        onPaginationModelChange={newModel => setPaginationModel(newModel)}
        onSortModelChange={setSortModel}
        pageSizeOptions={[10, 20, 50, 100]}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={rows}
        sortModel={sortModel}
      />
    </StyledPaper>
  );
};

export default Table;
