import apiClient from './apiClient';
import { CustomerFormData, FetchCustomersParams } from '../types/customerTypes';

export const fetchCustomers = async (params: FetchCustomersParams) => {
  const response = await apiClient.get('/customers', { params });
  return response.data;
};

export const createCustomer = async (customer: CustomerFormData) => {
  const response = await apiClient.post('/customers', customer);
  return response.data;
};

export const updateCustomer = async (
  customerId: string,
  customer: CustomerFormData
) => {
  const response = await apiClient.put(`/customers/${customerId}`, customer);
  return response.data;
};

export const deleteCustomer = async (customerId: string) => {
  const response = await apiClient.delete(`/customers/${customerId}`);
  if (response.status === 204) {
    return { success: true, message: 'Customer deleted successfully' };
  } else {
    throw new Error('Failed to delete customer');
  }
};

export const closeCustomer = async (customerId: string) => {
  const response = await apiClient.post(`/customers/${customerId}/close`);
  if (response.status === 200) {
    return { success: true, message: 'Customer closed successfully' };
  } else {
    throw new Error('Failed to close customer');
  }
};

export const fetchCustomerById = async (customerId: string) => {
  const response = await apiClient.get(`/customers/${customerId}`);
  return response.data;
};
