export enum Status {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum Action {
  CLOSE = 'CLOSE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export interface Customer {
  email: string;
  name: string;
  phone: string;
  uuid: string;
  balance: {
    amount: number;
    currency: string;
  };
  status: Status;
  actions: Action[];
  onboardedAt: Date;
}

export type CustomerFormData = Omit<
  Customer,
  'uuid' | 'actions' | 'onboardedAt'
> & {
  onboardedAt: string;
};

export interface FetchCustomersParams {
  name?: string;
  email?: string;
  phone?: string;
  dateStart?: string;
  dateEnd?: string;
  limit: number;
  offset: number;
  sort?: string;
  status?: string;
  sortDir?: 'asc' | 'desc';
}
