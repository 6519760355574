import axios from 'axios';

const apiClient = axios.create({
  baseURL: '/flatline/', // ToDo:Replace with your actual API endpoint
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
