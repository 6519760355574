/**
 * Formats a given date into a readable string format including time.
 *
 * @param {Date | string} date - The date to format. Can be a Date object or a string.
 * @returns {string} The formatted date and time string in 'Month Day, Year HH:MM' format.
 */
export const formatDateTime = (date: Date | string): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return dateObj.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};
