import React from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import SitemarkIcon from './Sitemark';
import ConfigInfo from './ConfigInfo';

const drawerWidth = 240;

interface StyledIconButtonProps {
  open?: boolean;
}

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledToolbar = styled(Toolbar)({
  paddingRight: '24px', // keep right padding when drawer closed
});

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(
  ({ open }) => ({
    marginRight: '36px',
    display: open ? 'none' : undefined,
  })
);

interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
}

const Header: React.FC<HeaderProps> = ({ open, handleDrawerOpen }) => {
  return (
    <AppBarStyled position="absolute" open={open}>
      <StyledToolbar>
        <StyledIconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          open={open}
        >
          <MenuIcon />
        </StyledIconButton>
        <SitemarkIcon />
        <ConfigInfo />
      </StyledToolbar>
    </AppBarStyled>
  );
};

export default Header;
